@import 'framework.less';
@var-colour: #337AB7;

.loading_element {
	background: #fafafa url('../../images/ajax-loader.gif') center center no-repeat;
	filter: alpha(opacity=50);
	-moz-opacity: 0.5;
	opacity: 0.5;
	position: fixed;
	left: 0; right: 0;
	top: 0; bottom: 0;
	height: 100%;
	min-height:100%;
	z-index: 999999;
	display: block;
}

.loading_element img{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.mb60 {
	margin-bottom: 60px;
}

.font14 {
	font-size: 1.4em!important;
}

.fatturahr {
	margin: 5px;
}

.h2nomargin {
	margin: 2px 0px;
}

.dropdown-submenu {
	position: relative;
}
.dropdown-submenu > .dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
}
.dropdown-submenu:hover > .dropdown-menu {
	display: block;
}
.dropdown-submenu:hover > a:after {
	border-left-color: #fff;
}
.dropdown-submenu.pull-left {
	float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
	left: -100%;
	margin-left: 10px;
}
.caret-right {
	border-bottom: 4px solid transparent;
	border-top: 4px solid transparent;
	border-left: 4px solid;
	display: inline-block;
	height: 0;
	width: 0;
}

.state-icon {
	left: -5px;
}
.list-group-item-primary {
	color: rgb(255, 255, 255);
	background-color: rgb(66, 139, 202);
}
.well .list-group {
	margin-bottom: 0px;
}
h3 {
	margin: 0px 0px 5px 0px;
}

.textImporto {
	font-size: 17px;
}

.rowTotali {
	margin: 10px 0px;
	font-weight: bold;
}

.boxImporti {
	margin-top: 30px;
	margin-bottom: 10px;
}

.dettaglioServizio {
	margin-bottom: 10px;
}

.dettaglioServizio textarea {
	margin-bottom: 5px;
	width: 100%;
}

.modal-xl {
	width: 1100px;
}

#editor {
	overflow: scroll;
	max-height: 300px;
}

.select2 {
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	color: #555555;
	display: block;
	font-size: 14px;
	height: 34px;
	line-height: 1.42857;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	width: 100%;
}
.select2-container--default .select2-selection--single {
	padding: 2px 0;
}
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
	border: none!important;
	height: 100%!important;
}

.panel-heading-chiaro {
	background-color: #fefefe !important;
}

.panel-title a {
	display: block;
}

#elenco-contabile .col-md-3 {
	padding-left: 5px;
	padding-right: 5px;
}

.button_div .btn {
	margin: auto 3px;
}

#date-range-div i {
	position: absolute;
	bottom: 10px;
	right: 24px;
	top: auto;
	cursor: pointer;
}

.legend {
	width: 10em;
	border: 1px solid #F5F5F5;
	padding: 5px;
	position: absolute;
	right: 20px;
	top: 20px;
	background-color: rgba(255,255, 255, 0.7);
}

.legend .title {
	display: block;
	/*margin-bottom: 0.5em;*/
	line-height: 1.2em;
	padding: 0 0.3em;
}

.legend .color-sample {
	display: block;
	float: left;
	width: 1em;
	height: 1em;
	border: 2px solid; /* Comment out if you don't want to show the fillColor */
	border-radius: 0.5em; /* Comment out if you prefer squarish samples */
	margin-right: 0.5em;
}

.dropdown-menu hr {
	margin: 10px 0px;
}

#save-mantenimento, #block-mantenimento {
	display: none;
}

.dt-right {
	text-align: right;
	padding-right: 100px!important;
	white-space: nowrap;
}

.sysconf1 {
	width: 25%;
}

.sysconf2 {
	width: 25%; 
	//margin-left: 3px;
}

.nosysconf1 {
	width: 49%; 
}

.nosysconf2 {
	width: 49%; 
	//margin-left: 3px;
}

.logout-button {
	cursor: pointer;
	border-top-left-radius: 0!important;
	border-top-right-radius: 0!important;
	border-bottom-left-radius: 0!important;
	height: 50px;
}

.modpsw-button {
	cursor: pointer;
	border-top-left-radius: 0!important;
	border-top-right-radius: 0!important;
	border-bottom-left-radius: 0!important;
	border-bottom-right-radius: 0!important;
	height: 50px;
}

html, body {
	height: 100%;
}

.app_wrapper {
	min-height: 100%;
	height: auto !important;
	margin: 0 auto -85px;
}
.app_footer, .app_push {
	height: 85px;
}

.put-margin-bottom {
	margin-bottom: 50px;
}

.no-margin-bottom {
	margin-bottom: 0px;
}

.details-row {
	border-top-style: dashed!important;
}

.dt-adjust {
	margin-left: 10px;
	display: inline-block;
}

.toolbar {
	height: 30px; 
	line-height: 1.5em;
}

td.expanded-group {
	background: url('../../images/details_close.png') no-repeat left center;
	cursor: pointer;
	padding-left: 30px !important;
}

td.collapsed-group {
	background: url('../../images/details_open.png') no-repeat left center;
	cursor: pointer;
	padding-left: 30px !important;
}

.nowrap {
	white-space: nowrap;
}

.margin-down {
	margin: 0 0 3px 0;
}

table.dataTable > thead > tr > th {
	font-size: 90%;
	padding: 5px 30px 5px 5px !important;
}

table.dataTable > thead > tr > th.sorting:after, 
table.dataTable > thead > tr > th.sorting_asc:after, 
table.dataTable > thead > tr > th.sorting_desc:after {
	top: auto!important;
}

.color-lightgrey {
	color: lightgrey;
}

.swal-text-left {
	text-align: left!important;
}

.popover {
	max-width: none!important;
}

.popover-note_servizio {
	color: black;
}

#mie-morosita thead .dt-right,
#mie-prossime-scadenze thead .dt-right,
#mio-conto thead .dt-right,
#mie-fatture-emesse thead .dt-right {
	text-align: left!important;
}

#mie-morosita tbody .dt-right,
#mie-prossime-scadenze tbody .dt-right,
#mio-conto tbody .dt-right,
#mie-fatture-emesse tbody .dt-right,
#mie-morosita tfoot .dt-right,
#mie-prossime-scadenze tfoot .dt-right,
#mio-conto tfoot .dt-right,
#mie-fatture-emesse tfoot .dt-right {
	padding-right: 80px!important;
}

.swal-wide {
	width: 650px!important;
}

.non-pagato {
	color: #333;
	padding: 0;
}
.non-pagato:hover {
	color: red;
}

.d-none {
	display: none;
}

// Contabile Create
.lista_rate {
	margin-bottom: 20px;
}
.titolo_lista_rate {
	padding-bottom: 5px;
}
.lista_rate .checked-list-box {
	border-top: 1px solid #ddd!important;
	max-height: 165px;
	overflow-y: auto;
}
.lista_rate .checked-list-box .list-group-item {
	border-radius: 0!important;
	border-bottom: 1px solid #ddd!important;
	border-left: 1px solid #ddd!important;
	border-right: 1px solid #ddd!important;
	border-top: 0!important;
	margin-bottom: 0!important;
}
.lista_rate .checked-list-box .list-group-item > .rata-icon {
	float: right;
	margin-left: 15px;
}

// Textarea
textarea {
	resize: none;
}

// Colors
.color-green {
	color: #5cb85c;
}
.color-orange {
	color: orange;
}
.color-red {
	color: #FF0000;
}
.color-yellow {
	color: #FCD209;
}

// Background Colors
.bg-white {
	background-color: white!important;
}

// Input Number Arrows
.show-arrows-left::-webkit-inner-spin-button,
.show-arrows-right::-webkit-inner-spin-button {
	bottom: 7px;
	opacity: 1;
	position: absolute;
	top: 7px;
}
.show-arrows-left::-webkit-inner-spin-button {
	left: 10px;
}
.show-arrows-right::-webkit-inner-spin-button {
	right: 10px;
}

// Input Icon
.input-no-icon {
	position: relative;
}
.input-icon {
	position: relative;
}
.input-icon > i {
	position: absolute;
	display: block;
	transform: translate(0, -50%);
	top: 50%;
	pointer-events: none;
	width: 25px;
	text-align: center;
	font-style: normal;
}
.input-icon > input {
	padding-left: 22px;
	padding-right: 0;
}
.input-icon-right > i {
	right: 0;
}
.input-icon-right > input {
	padding-left: 0;
	padding-right: 22px;
	text-align: right;
}

.btn-shortcut {
	float: right;
	margin-left: 10px;
}

// btn group
.dropdown-menu .btn {
	border-radius: 0;
	text-align: left;
}
.my-btn-group-dropdown {
	position: relative;
}
.my-btn-group-dropdown .dropdown-toggle {
	position: absolute;
	right: 0;
	z-index: 999;
}

// C2S

.c2sd_summary {
	font-size: 80%;
	line-height: 1.2;
	padding-left: 5px;
}